import React, { useState, useEffect } from 'react';

function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {    
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight/2) {
        //todo ogarnac ten przycisk bo jest dramatyczny
        setIsVisible(true);
      } else {        
        setIsVisible(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {isVisible && (
        <button onClick={scrollToTop} className="scroll-to-top">            
            <i className="bi bi-arrow-up fs-5"></i>

        </button>
      )}
    </div>
  );
}

export default ScrollToTopButton;