import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './global.css';

import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
document.title = 'Kancelaria Adwokacka Grzegorz Cach';
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>);