import React, { useState } from 'react';
import { Toast } from "react-bootstrap";
import axios from "axios";

const ContactModal = ({ showModal, handleClose }) => {
  function getData () {
    axios({
      method: "POST",
      url:"/PHP/action.php/message",
      data: {
        name: formData.name,
        contactInfo: formData.contactInfo,
        subject: formData.subject,
        message: formData.message
      },
      headers: {
        "Content-Type": "application/json"
      }
    })
    .then((response) => {
      changeBlockStatus(false)
      if(response.data.status === 'ok'){
        handleClose();
        setShowToast(true);
        setFormData(initialState);
      }else{
        if(response.data.comment){
          setErrorMessage(response.data.comment);
        }
        setErrorToast(true)
      }
    }).catch((error) => { 
      handleClose();  
      setErrorToast(true)
      changeBlockStatus(false)
    })
  }

  const initialState = {
    name: '',
    contactInfo: '',
    subject: '',
    message: ''
  };
  const [blockRequest, changeBlockStatus] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);  
  const [formData, setFormData] = useState(initialState);
  const [errorMessage, setErrorMessage] = useState("");

    //todo chowanie hamburger menu po kliku
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    changeBlockStatus(true)
    e.preventDefault();        
    if(blockRequest === false) getData(formData)
  };

  return (
    <div>
      <Toast style={{ display: showModal ? 'none' : '' }} className="center-toast" onClick={()=> setShowToast(false)} show={showToast} onClose={() => setShowToast(false)} autohide delay={5000} animation={true}> <Toast.Header className="d-block">
          <strong className="text-center h4">Wysłano wiadomość</strong>
        </Toast.Header>
        <Toast.Body className="text-center h6 fw-normal"> Skontaktuję się z Państwem wybraną metodą </Toast.Body>
      </Toast>
      <Toast style={{backgroundColor: '#6b6b6b'}} className="center-toast" onClick={()=> {setErrorToast(false); setErrorMessage("")}} show={errorToast} onClose={() => {setErrorToast(false); setErrorMessage("");}} delay={5000} autohide animation={true}> <Toast.Header className="d-block">
          <strong className="text-center h4">Nie udało się wysłać wiadomości <br />{errorMessage} </strong>
        </Toast.Header>
        <Toast.Body className="text-center h6 fw-normal"> Proszę o kontakt telefoniczny lub mailowy </Toast.Body>
      </Toast>
      <div className={`modal ${showModal ? 'd-block fade-scale in' : 'fade-scale d-block' }`} tabIndex="-1" role="dialog">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Formularz kontaktowy:</h5>
              <button type="button" className="btn-close color-light" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose} style={{ filter: 'invert(1)' }}></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name">Imię i nazwisko:</label>
                      <input type="text" className="form-control" id="name" name="name" value={formData.name} onChange={handleChange} placeholder="Wprowadź imię" required minLength="5" maxLength="40" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="contactInfo">E-mail lub telefon:</label>
                      <input type="text" className="form-control" id="contactInfo" name="contactInfo" value={formData.contactInfo} onChange={handleChange} placeholder="Wprowadź e-mail lub telefon" required minLength="5" maxLength="35" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="subject">Temat:</label>
                      <input type="text" className="form-control" id="subject" name="subject" value={formData.subject} onChange={handleChange} placeholder="Wprowadź temat" required minLength="5" maxLength="150" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="message">Treść wiadomości:</label>
                      <textarea className="form-control" id="message" name="message" rows="4" value={formData.message} onChange={handleChange} placeholder="Wprowadź wiadomość" required minLength="50" maxLength="1500" />
                    </div>
                  </div>
                  <div className="col-md-12 text-center pt-3">
                    <button type="submit" className="btn color-send"> Wyślij </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;