import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png'
function MyComponent() {
  const [scrollY, setScrollY] = useState(0);  
  const [isVisible, setIsVisible] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500);
    const handleScroll = () => {
      if(window.innerHeight / 2 > window.scrollY && Math.abs(window.scrollY - scrollY * 3) > 20){        
        //console.log('xd', window.scrollY, scrollY)
        setScrollY(window.scrollY/3);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeout);
    };
  }, [scrollY]);
  return (
    <div style={{marginBottom: `${scrollY * -0.5 - 5}px`}}>
    <div style={{position: 'relative', overflow: 'hidden', height: `${ window.innerHeight > 1200 ? 1200 - scrollY : window.innerHeight - scrollY}px`}}>
      <div style={{backgroundImage: 'url("./background2.jpg")', height: window.innerHeight, overflow: 'hidden', position: 'relative', backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
          <div className="col-4"></div>
          <div className={`col-4 ${isVisible ? 'fade-out-map' : 'fade-out-map hidden' }`}>
            <div className="card mx-auto bg-transparent" style={{border: '5px solid #fff', borderRadius: '0px', maxWidth: "120px", aspectRatio: '1/1' }}>
              <div className="text-center">
                <h5 className="card-title">
                  <img src={logo} alt="prawo_rodzinne" style={{maxHeight: '100px', filter: 'invert(100%)', marginBottom: '-90px'}} />
                  <br />
                </h5>
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </div>
    <div className="bg-light" style={{backgroundBlendMode: 'darken', backgroundImage: 'url("./txtBg.jpg")', backgroundRepeat: 'round', transition: 'transform 0s linear', transform: `translateY(${scrollY * -0.5}px)`}}>
      <div className="container pb-4 mt-3">
        <div className="home-title">
          <div className="h3 text-center font_lora pt-4">O kancelarii</div>
        </div>
        <div className="h5 text-center font_sans pt-2"> Kancelaria Adwokacka Grzegorz Cach świadczy usługi z zakresu różnych dziedzin prawa na rzecz przedsiębiorców oraz osób fizycznych. W oparciu o wieloletnie doświadczenie świadczymy usługi  dostosowane do indywidualnych potrzeb i wymagań klienta, przy zachowaniu najwyższej jakości i efektywności. Szeroki wachlarz usług świadczony jest przede wszystkim w zakresie prawa gospodarczego, cywilnego, rodzinnego, karnego, karnego skarbowego oraz administracyjnego.</div>
        <div className="home-title">
          <div className="h3 text-center font_lora pt-4">O mnie</div>
        </div>
        <div className="h5 text-center font_sans pt-2"> Jestem absolwentem Uniwersytetu im Marii Curie-Skłodowskiej w Lublinie. W roku 1989 ukończyłem aplikacje adwokacką. Usługi prawnicze wykonywałem od roku 1990 w ramach zespołu adwokackiego, a od roku 1992 prowadzę indywidualną Kancelarie Adwokacką w Rzeszowie. 
 W Programie Narodowych Funduszy Inwestycyjnych byłem członkiem i przewodniczącym rad nadzorczych kilku spółek Skarbu Państwa. Zdobyte doświadczenie wykorzystuje do obsługi podmiotów gospodarczych.
Wielokrotnie pełniłem funkcję patrona aplikantów adwokackich.
W ramach usług kancelarii świadczę pomoc, podmiotom gospodarczym, osobom fizycznym w szczególności z zakresu prawa gospodarczego cywilnego, rodzinnego i karnego.
        </div>
        <div className="home-title">
          <div className="h3 text-center font_lora pt-4">Zakres usług</div>
        </div>
        <div className="h5 text-center font_sans pt-2">Kancelaria adwokacka Grzegorz Cach oferuje pomoc prawną zarówno w formie stałej obsługi przedsiębiorców jak i doraźnej pomocy osobom prawnym i osobom fizycznym, w szczególności w następującym zakresie:</div>
        <div className="text-center">
  <button style={{borderRadius: '0', fontFamily: 'lora'}} type="button" className="btn btn-secondary" onClick={() => {window.location.href="/services"}}>Dowiedz się więcej</button>
</div>
      </div>
    </div>
  </div>
    );  
}

export default MyComponent;