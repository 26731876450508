import React, { useState } from 'react'; 
function Contact() {
  const [streetViewVisible, setStreetViewVisible] = useState(false);
  const [defaultViewVisible, setDefaultViewVisible] = useState(true);

  const toggleView = (view) => {
      setStreetViewVisible(!streetViewVisible);
      setDefaultViewVisible(!defaultViewVisible);
      changeAtribute();    
  }

  const changeAtribute = () => {
    const element = document.getElementById('change_button');

    if (element) {
      const dataFront = element.getAttribute('data-front');
      const dataBack = element.getAttribute('data-back');

      element.setAttribute('data-front', dataBack);
      element.setAttribute('data-back', dataFront);
    }
  };
   return (
    <div>
      <div className="spec">
        <h1>Kontakt</h1>
      </div>
      <div className = "bg-light">
      <div className="container">

      <div className="album py-5 pb-3">
        <div className = "row">

        <div className="col-md-4 col-12 mt-3 d-flex">
          <div className="card bg-dark text-light card-aspect box-contact w-100">
            <div className="card-body text-center box-contact-inner">
              <h4 className="card-title font-con">Telefon</h4>
              <span className="bi bi-telephone fs-4"></span>
              <p className="text-center font-nav">+48 606 238 430</p>
            </div>
          </div>
        </div>
        
        <div className="col-md-4 col-12 mt-3 d-flex">
          <div className="card bg-dark text-light card-aspect box-contact w-100">
            <div className="card-body text-center box-contact-inner">
              <h4 className="card-title font-con">E-mail</h4>
              <span className="bi bi-envelope fs-4"></span>
              <p className="text-center font-nav">adw.cach@vp.pl</p>
            </div>
          </div>
        </div>
        
        <div className="col-md-4 col-12 mt-3 d-flex">
          <div className="card bg-dark text-light card-aspect box-contact w-100">
            <div className="card-body text-center box-contact-inner">
              <h4 className="card-title font-con">Adres</h4>
              <span className="bi bi-geo-alt fs-4"></span>
              <p className="text-center font-nav">ul. Kolejowa 1, pok 7, II piętro 35-073 Rzeszów</p>
            </div>
          </div>
        </div>
        
        <div className="col-md-5 mx-auto mt-3 d-flex">
          <div className="card bg-dark text-light card-aspect box-contact">
            <div className="card-body text-center box-contact-inner">
              <h4 className="card-title font-con">Godziny otwarcia kancelarii</h4>
              <span className="bi bi-clock fs-4"></span>
              <p className="text-center font-nav">Godzina spotkania do ustalenia po kontakcie telefonicznym.<br/>W wyjątkowych przypadkach istnieje możliwość spotkania się w sobotę lub niedzielę. </p>
            </div>
          </div>
        </div>
        </div>
      </div>
      <hr/>
        <div className="row pb-3 pt-3">
        <span className={`contact_view col-sm-4 col-12 text-center ${defaultViewVisible ? 'fade-out-map' : 'fade-out-map hidden'}`} id="map_name">
            {defaultViewVisible && ('Kancelaria pokazana na mapie')}
        </span>         
         <span onClick={()=> toggleView()} id = "change_button" className="btn-flip col-sm-4 col-12" data-back="Widok street view" data-front="Widok na mapie" style = {{fontSize: '11px'}}></span>
         <span className={`contact_view col-sm-4 col-12 text-center ${streetViewVisible ? 'fade-out-map' : 'fade-out-map hidden'}`} id="map_name2">
            {streetViewVisible && ('Widok na wejście do kancelarii')}
        </span> 
        </div>
      </div>
      </div>
      <div className="row" style={{margin: 0, padding: 0, marginTop: '-10px'}}> 
        {/*todo wywalic ui mapy*/} 
        {streetViewVisible && ( <iframe style={{width: '100%'}} title="streetViewView" id="streetViewView" className="mt-2 g-0" height="500px" src="https://www.google.com/maps/embed?pb=!4v1695751919183!6m8!1m7!1sdKu3cp8WqbTih30n-rKFrg!2m2!1d50.04068167686574!2d22.00722419283092!3f105.35271722746016!4f-3.125789382598171!5f1.0968515040232396&disableDefaultUI=1"></iframe>)} 
        {defaultViewVisible && ( <iframe style={{width: '100%'}} title="defaultViewVisible" id="defaultViewVisible" className="mt-2 g-0" height="500px" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1046.7044612983625!2d22.008078192710737!3d50.04050328941648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfb0044b87fab%3A0x40b22f113f094f05!2sKancelaria%20Adwokacka%20Grzegorz%20Cach!5e0!3m2!1spl!2spl!4v1695234911897!5m2!1spl!2spl"></iframe>)} 
      </div>
    </div>
); } export default Contact;