import React from 'react';

function MyComponent() {
  return (
    <footer className="footer py-4 bg-dark-subtle">
      <div className="row" style = {{margin: 0, padding: 0, marginBottom: '25px'}}>
      <div className="col-md-1"></div>
        <div className="col-md-4 col-xs-12">
          <div className="row">
            <div className="col-md-6">
              <div className="row row-cols-2 g-2" style={{ height: '100%' }}>
                <div className="col-12" style={{ height: '100%' }}>
                  <div className="card" style={{ height: '100%', border: "0px"}}>
                    <div className="card-body text-center p-2 bg-dark-subtle">
                      <h2 className="card-title ">
                        <span className="bi bi-telephone"></span>
                        <br /> Telefon
                      </h2>
                      <span className="card-text">+48 606 238 430</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row row-cols-2 g-2" style={{ height: '100%' }}>
                <div className="col-12" style={{ height: '100%' }}>
                  <div className="card" style={{ height: '100%', border: "0px"}}>
                    <div className="card-body text-center p-2 bg-dark-subtle">
                      <h2 className="card-title">
                        <span className="bi bi-envelope"></span>
                        <br /> E-mail
                      </h2>
                      <span className="card-text">adw.cach@vp.pl</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="row row-cols-2 g-2" style={{ height: '100%' }}>
                <div className="col-12" style={{ height: '100%' }}>
                  <div className="card" style={{ height: '100%', border: "0px"}}>
                    <div className="card-body text-center p-2 bg-dark-subtle">
                      <h2 className="card-title">
                        <span className="bi bi-geo-alt"></span>
                        <br /> Adres
                      </h2>
                      <span className="card-text">ul. Kolejowa 1, pok 7, <br />II piętro <br />35-073 Rzeszów </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row row-cols-2 g-2" style={{ height: '100%' }}>
                <div className="col-12" style={{ height: '100%' }}>
                  <div className="card" style={{ height: '100%', border: "0px"}}>
                    <div className="card-body text-center p-2 bg-dark-subtle">
                      <h2 className="card-titler">
                        <span className="bi bi-clock"></span>
                        <br /> Godziny otwarcia
                      </h2>
                      <span className="card-text">Godzina spotkania do ustalenia po kontakcie telefonicznym.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <iframe title="defaultView" id="defaultView" className="mt-2 g-0" width="100%" height="100%" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1046.7044612983625!2d22.008078192710737!3d50.04050328941648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfb0044b87fab%3A0x40b22f113f094f05!2sKancelaria%20Adwokacka%20Grzegorz%20Cach!5e0!3m2!1spl!2spl!4v1695234911897!5m2!1spl!2spl"></iframe>
        </div>
        <div className="col-md-2 col-xs-12 justify-content-center align-self-center">
              <a href="https://www.facebook.com/profile.php?id=61551701620839" target="_blank" rel="noopener noreferrer">
                <span  className="bi bi-facebook" style={{fontSize: '70px', color: '#0866FF'}}></span>  
              </a>
        </div>        
      </div>
      <hr/>
      <div className="row align-items-center mt-4" style = {{margin: 0, padding: 0}}>
        <div className="">© Copyright Kancelaria Adwokacka Grzegorz Cach - Adwokat Rzeszów</div>
      </div>
  </footer>
  );
}

export default MyComponent;