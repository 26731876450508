import React, { useState } from 'react';
import logo from './assets/logo.png'
import Form from './Form.js'
function App() {

  window.addEventListener('scroll', () => {
    const navbar = document.getElementById('navbarF');
    if (navbar && window.scrollY > 10) {
      navbar.style.top = '0';
    } else if(navbar){
      const header = document.getElementById('headerF');
      const contentHeight = header.clientHeight;
      navbar.style.top = contentHeight + 'px';
    }
  });
  
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  return (
    <div>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark" id = "navbarF" style = {{position: 'fixed', width: '100%', zIndex: '100'}}>
        <div className="container" style = {{margin: 'auto'}}>
          <div className = "row mx-auto justify-content-between">

            <a className = "text-center col-12 col-lg-2" href="/"><img src= {logo} alt ="navLogo" id="navbarLogo" style = {{marginTop: '5px'}} /></a>
            <a className="navbar-brand font-cinreg col-9 col-lg-9 font-sizer" href="/" >Adwokat Grzegorz Cach</a>
            <button className="navbar-toggler col-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <i className="navbar-toggler-icon"></i>
            </button>

            </div>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
                    <li className="nav-item"><a className="nav-link navbar-data font-nav" href="/">Strona główna</a></li>   
                    <li className="nav-item"><a className="nav-link navbar-data font-nav" href="/services">Świadczone usługi</a></li>                        
                    <li className="nav-item font-navbar"><a className="nav-link navbar-data font-nav" href="/contact">Kontakt</a></li>
                    <li className="nav-item modal-link"><span className="nav-link contact-message navbar-data font-nav" onClick={handleOpenModal}>Napisz do mnie!</span></li>
                </ul>
            </div>
        </div>
      </nav>
      <Form showModal={showModal} handleClose={handleCloseModal} />
    </div>
  );
}

export default App;