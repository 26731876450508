import React from 'react'; function About() { return ( <div>
  <div className="spec">
    <h1>Moje specjalizacje</h1>
  </div>
  <div className="album bg-light">
    <div className="row justify-content-center container m-auto">
      <div className="col-12 col-xl-6 mt-3">
        <div className="box-mine card mx-auto">
          <div className="card-body text-center bg-light">
            <h5 className="card-title font_lora">
            <img src= '../icons/prawo_cywilne.png' alt="prawo_cywilne" style = {{maxHeight: '25px'}}/>
            <br/>
              Prawo cywilne
            </h5>
            <ul className="card-text font_sans">
              <li>Sprawy o zapłatę,</li>
              <li>Sprawy o odszkodowanie i zadośćuczynienie,</li>
              <li>Sprawy dotyczące  nieruchomości,</li>
              <li>Sprawy spadkowe,</li>
              <li>Przygotowywanie i opiniowanie umów cywilnych.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 mt-3">
        <div className="no-box card mx-auto">
          <div className="card-body text-center bg-light">
            <h5 className="card-title font_lora">
            <img src= '../icons/prawo_rodzinne.png' alt="prawo_rodzinne" style = {{maxHeight: '25px'}}/>
              <br/>
              Prawo rodzinne
            </h5>
            <ul className="card-text font_sans">
              <li>Rozwód i separacja,</li>
              <li>Władza rodzicielska,</li>
              <li>Alimenty (ustalenie/zmiana/uchylenie obowiązku alimentacyjnego),</li>
              <li>Kontakty rodzicielskie,</li>
              <li>Ustalenie lub zaprzeczenie ojcostwa/macierzyństwa.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 mt-3">
        <div className="box-mine card mx-auto">
          <div className="card-body text-center bg-light">
          <h5 className="card-title font_lora">
            <img src= '../icons/prawo_karne.png' alt="prawo_karne" style = {{maxHeight: '25px'}}/>
              <br/>
              Prawo karne
            </h5>
            <ul className="card-text font_sans">
              <li>Obrona obwinionych w sprawach o wykroczenia,</li>
              <li>Obrona oskarżonych,</li>
              <li>Reprezentacja pokrzywdzonych w postępowaniu karnym,</li>
              <li>Reprezentacja osób podejrzanych o popełnienie przestępstwa w postępowaniu przygotowawczym (przed Policją czy prokuratorem),</li>
              <li>Reprezentacja osób skazanych.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 mt-3">
        <div className="no-box card mx-auto">
          <div className="card-body text-center bg-light">
          <h5 className="card-title font_lora">
            <img src= '../icons/prawo_pracy.png' alt="prawo_pracy" style = {{maxHeight: '25px'}}/>
              <br/>
              Prawo pracy i ubezpieczeń społeczynch
            </h5>
            <ul className="card-text font_sans">
              <li>Ochrona praw pracowniczych,</li>
              <li>Spory z pracodawcą,</li>
              <li>Dochodzenie odszkodowań z tytułu wypadków przy pracy,</li>
              <li>Ustalenie prawa do emerytury, renty.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 mt-3">
        <div className="box-mine card mx-auto">
          <div className="card-body text-center bg-light">
          <h5 className="card-title font_lora">
            <img src= '../icons/prawo_gospodarcze.png' alt="prawo_gospodarcze" style = {{maxHeight: '25px'}}/>
              <br/>
              Prawo gospodarcze
            </h5>
            <ul className="card-text font_sans">
              <li>Dochodzenie roszczeń w sprawach gospodarczych,</li>
              <li>Sporządzenie i opiniowanie umów spółek oraz wszelakich umów handlowych,</li>
              <li>Dokonywanie wpisów do rejestrów przedsiębiorców,</li>
              <li>Udział w negocjacjach,</li>
              <li>Upadłość przedsiębiorcy oraz konsumenta.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 mt-3">
        <div className="no-box card mx-auto">
          <div className="card-body text-center bg-light">
          <h5 className="card-title font_lora">
            <img src= '../icons/prawo_administracyjne.png' alt="prawo_administracyjne" style = {{maxHeight: '25px'}}/>
              <br/>
              Prawo administracyjne
            </h5>
            <ul className="card-text font_sans">
              <li>Sporządzanie odwołania od decyzji administracyjnej,</li>
              <li>Reprezentacja strony przed organami administracji publicznej i sądami administracyjnymi.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-6 mt-3 mb-2">
        <div className="no-box card mx-auto">
          <div className="card-body text-center bg-light">
          <h5 className="card-title font_lora">
            <img src= '../icons/windykacja_naleznosci.png' alt="windykacja_naleznosci" style = {{maxHeight: '25px'}}/>
              <br/>
              Inne czynności dostosowane do potrzeb klienta
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> ); } export default About;