import React from 'react';
function App() {
  return (
    <div>
        <nav className="navbar navbar-expand-lg bg-dark-subtle" id = "headerF">
        <div className="container">
                <ul className = "nav navbar-nav header-color" >
                    <li><a href="tel:+48606238430" className="me-3 bi-telephone-fill fs-5" style = {{textDercoartion: 'none', color: 'olive'}}> +48 606 238 430</a></li>
                    <li><a href="mailto:adw.cach@vp.pl" className="me-3 bi-envelope-fill fs-5" style = {{color: 'olive'}}> adw.cach@vp.pl</a></li>
                </ul>
                <ul className="nav navbar-nav navbar-right header-color">                 
                    <li>
                        <a href="https://www.facebook.com/profile.php?id=61551701620839" target="_blank" rel="noopener noreferrer">
                            <span style={{color: '#0866FF'}} className="bi fs-3 bi-facebook"></span>      
                        </a>
                    </li>
                </ul>  
            </div>         
      </nav>
    </div>
  );
}

export default App;