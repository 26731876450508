import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import ReactDOM from "react-dom/client";

import Home from './components/Home.js'; 
import Contact from './components/Contact.js'
import Services from './components/Services.js'; 

import Navbar from "./Navbar.js"
import Header from "./Header.js"
import Footer from "./Footer.js"

import ScrollToTopButton from './ScrollButton.js';

export default function App() {
  return (
    <BrowserRouter>
    <Header />
    <Navbar />  
      <Routes>        
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />        
        <Route path="/services" element={<Services />} />

        <Route path="/kontakt" element={<Home />} />
        <Route path="/kontakt.html" element={<Home />} />
      </Routes>
      <Footer />  
      <ScrollToTopButton/>
    </BrowserRouter>
  );
}